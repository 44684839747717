/* ==============================================
|              SIDEBAR STYLE START
================================================= */
:root {
    --main-color:#115ECE !important;
    /*--main-color: #a79292 !important;*/
    --deleteIcon-color: var(--main-color);
}

body {
    background: #ebedee !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.main__root {
    width: 233.1px;
    height: 100vh;
    background-color: white;
    left: -233px;
    top: 0;
    position: fixed;
    transition: ease-in-out 0.3s;
    z-index: 1200;
}

.main__root::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(124, 128, 128);
}

.main__root:hover {
    margin-left: 233px;
    position: fixed;
    overflow-y: scroll;
    transition: ease-in-out 0.3s;
}

/* ==============================================
|              SIDEBAR STYLE END
================================================= */

/* .MuiListItemIcon-root {
  min-width: 40px !important;
} */

/* table header global css Start */
.MuiTableCell-stickyHeader {
    background-color: var(--main-color) !important;
    color: white !important;
    height: 50px !important;
}

/* table header global css End */
.MuiIconButton-root {
    padding: 3px !important;
}

.MuiTableCell-root {
    height: 43px !important;
}

/*========================================================
|                   icons css start
 ======================================================= */
.MuiTableCell-root {
    padding: 0px !important;
    padding-left: 10px !important;
}

.iconbtn {
    color: var(--main-color) !important;
}

/* icons css End */

/* =====================================================
|                 kyc modal  button css
======================================================= */

.MuiTypography-body1 {
    color: #00000091;
}

/*=======================================================
|                  DRAWERS STYLES CSS START
=========================================================*/
#drawer_icon_color {
    font-size: 21px;
    margin-left: 3px;
    color: var(--main-color);
}

#about_button_color {
    background: var(--main-color);
    color: #fff;
}

/*==========================================
|                  jobs
===========================================*/
.header__title {
    color: var(--main-color);
    font-size: 16px;
}


@media screen and (max-width: 1023px) {
    /*tolbar appbar*/
    .MuiToolbar-gutters {
        padding-left: 0px !important;
        padding-right: 24px !important;
    }
}

/*====================================================
|                  SLIDER IMAGE CSS START
======================================================*/

.editBtn {
    display: none;
    align-self: flex-end;

}

.editBtn:hover {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    position: sticky;
    margin-top: calc(-28% - 100px);
}

.imgbtn {
    width: 100%;
    height: 100%;
    /*position: absolute;*/
}

.man {
    height: 250px;
    width: 100%;
    border: 1px dashed var(--main-color);
    position: relative;
}

.man:hover + .editBtn {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    position: sticky;
    margin-top: calc(-28% - 100px);

}

/*====================================================
|                  SLIDER IMAGE CSS END
======================================================*/

/*.MuiSwitch-thumb {*/
/*    color: var(--main-color);*/
/*    margin-top: 5px;*/
/*}*/

/*.MuiSwitch-sizeSmall .MuiSwitch-thumb {*/
/*    margin-top: 0px;*/
/*}*/


/*==========================================================
|                       global css
============================================================*/
.cqWyjl svg {
    color: var(--deleteIcon-color) !important;
}

.gNmhIT {
    border: 1px dotted var(--main-color);
}

.kzdUGL svg {
    color: var(--main-color) !important;
}

/*==============================================
|           global spins css code style
===============================================*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.image-box {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    background: #ece5e5;
    /*position: relative;*/
}

 .delete-box {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px;
    border-radius: 0 10px 0 10px;
    background: #f82121;
    cursor: pointer;
    color: #fff;
}

